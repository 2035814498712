
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="卡号" prop="cardNumber">
          <el-input
            v-model="formModel.cardNumber"
            placeholder="请输入卡号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNo">
          <el-input
            v-model="formModel.sortNo"
            placeholder="请输入排序号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >保存</el-button
      >
      <el-button type="primary" @click="handleSubmitAndSync" :loading="submitting"
        >保存并同步</el-button
      >
      <el-button @click="closeDialog">取 消</el-button>
      
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import communityCardApi from "@/api/base/communityCard";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        id: [{ required: true, message: "不能为空", trigger: "blur" }],
        createBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        createTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        delFlag: [
          { required: true, message: "是否删除不能为空", trigger: "blur" },
        ],
        cardNumber: [
          { required: true, message: "卡号不能为空", trigger: "blur" },
        ],
        personId: [
          { required: true, message: "关联人员id不能为空", trigger: "blur" },
        ],
        personPopedomId: [
          { required: true, message: "角色id不能为空", trigger: "blur" },
        ],
        status: [
          {
            required: true,
            message: "卡片状态(正常,未开卡等不能为空",
            trigger: "blur",
          },
        ],
        sortNo: [
          { required: true, message: "排序号不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.isSync = false;
            self.formModel.status = "0";

            if (id == null || id.length == 0) {
              return communityCardApi.add(self.formModel);
            } else {
              return communityCardApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
    handleSubmitAndSync() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            self.formModel.isSync = true;
            self.formModel.status = "0";

            if (id == null || id.length == 0) {
              return communityCardApi.add(self.formModel);
            } else {
              return communityCardApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return communityCardApi.create();
      } else {
        return communityCardApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>